import GoogleSheetApi from "../../apis/Google/GoogleSheetApi";
import StripChatApi from "../../apis/StripChat/StripChatApi";
import ChaturbateApi from "../../apis/Chaturbate/ChaturbateApi";

Array.prototype.shuffle = function () {
  var array = this;
  var m = array.length,
    t,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
};

function makeBaseDescriptions() {
  let descriptions = {
    head: {
      title: "",
      sepr: "",
    },
    header: {
      type: 0,
      title: "",
      contents: [],
    },
    footer: {
      type: 0,
      title: "",
      top: {
        contents: [],
      },
      middle: {
        title: "",
        contents: [],
      },
      bottom: {
        contents: [],
      },
    },
  };
  return descriptions;
}

const HomeState = {
  pageIndex: 1,
  countPerPage: 6 * 10,
  countOfGetModels: 499,
  paginationDeviation: 4,
  thumbs: [
    // {
    //   img: "",
    //   views: "",
    //   title: "",
    //   modelName: "",
    // },
  ],
  currentThumbs: [],
  descriptions: makeBaseDescriptions(),

  //
  control: {
    isRefresh: true,
  },
};

function defaultDescriptions() {
  let descriptions = {
    head: {
      title: "Nude Live Sex Cams and Free Adult chat",
      sepr: "HereCams: Nude Live Sex Cams and Free Adult chat",
    },
    header: {
      title: "Nude Live Sex Cams",
      contents: [
        "We gathered the best live sex cams from cams sites like Stripchat, Chaturbate and Live Jasmin. You'll find 24/7 a week thousands of naked women, guys, trans and couples available for live cams to chat with you. As soon as you'll start watching live porn cams with amateurs you won't go back to the boring and traditional porn videos! Even though girls are not always naked, at any time you might see them fingering or getting fucked. Hide your screen, this is definitely NSFW.",
      ],
    },
    footer: {
      title: "FREE ADULT WEBCAMS WITH THE HOTTEST CAM GIRLS",
      top: {
        contents: [
          "No more need to open separately Stripchat, Flirt4Free, Bonga Cams or Live Jasmin to find the live sex cam that excites you.",
        ],
      },
      middle: {
        title:
          "You just have to browse the categories of HereCams to find your happiness among the physical characteristics that you like:",
        contents: [
          "Age: all ages (+18 years old) whether you like teen cam girls, cougars or even mature.",
          "Hair color: brunettes, blondes or cosplay with blue hair, as you prefer!",
          "Body: from skinny teens to curvy naked women with big tits like Rapunzel.",
          "Gender: thanks to HereCams there is nothing easier for you to find sex cams with hot girls, guys, trannies or couples.",
          "Ethnicity: Asian teens like Mei_tin, naked Latina girls like Carolina Novoa, ebony camgirls and more",
          "Attributes: hot milf with big tits, young teen with tight pussy...",
        ],
      },
      bottom: {
        contents: [
          "If you like fetishism, you will also find your happiness with dominatrixes or submissive women ready to do anything to make you cum on cam.",
          "The shows are 100% free in public, but you can leave tips to see a dance, blowjob, masturbation, penetration or any other fantasy that's on the menu. Some models have an interactive sex toy that allows you to participate and give them pleasure. They can also see you if they accept cam2cam.",
        ],
      },
    },
  };
  return descriptions;
}

function formatDescriptions() {
  let descriptions = {
    head: {
      title: "HEAD_TITLE",
      sepr: "HEAD_SEPR",
    },
    header: {
      title: "KEY_HEADER_TITLE",
      contents: ["KEY_HEADER_COMTENTS_1", "KEY_HEADER_COMTENTS_2"],
    },
    footer: {
      title: "KEY_FOOTER_TITLE",
      top: {
        contents: [
          "KEY_FOOTER_TOP_CONTENTS_1",
          "KEY_FOOTER_TOP_CONTENTS_2",
          "KEY_FOOTER_TOP_CONTENTS_3",
        ],
      },
      middle: {
        title: "KEY_FOOTER_MIDDLE_TITLE",
        contents: [
          "KEY_FOOTER_MIDDLE_CONTENTS_1",
          "KEY_FOOTER_MIDDLE_CONTENTS_2",
          "KEY_FOOTER_MIDDLE_CONTENTS_3",
        ],
      },
      bottom: {
        contents: [
          "KEY_FOOTER_BOOTOM_CONTENTS_1",
          "KEY_FOOTER_BOOTOM_CONTENTS_2",
          "KEY_FOOTER_BOOTOM_CONTENTS_3",
        ],
      },
    },
  };
  return descriptions;
}

function parseDescriptions(sheets, searchText) {
  console.log("search key in sheet: ", searchText);
  if (!sheets || sheets.length <= 0) {
    return defaultDescriptions();
  }

  // match search keyword
  let matchedKey = "DEFAULT";
  if (searchText) {
    for (let key in sheets) {
      if (
        key.indexOf("_KEYWORDS") > -1 &&
        sheets[key].toLowerCase().indexOf(searchText.toLowerCase()) > -1
      ) {
        matchedKey = key.split("_")[0];
        // console.log("key: ", key, sheets[key], matchedKey);
      }
    }
  }

  let descriptions = makeBaseDescriptions();
  for (let key in sheets) {
    // console.log("KEY:", key);
    if (key === "HEAD_TITLE") {
      descriptions.head.title = sheets[key];
      // console.log("parse head.title:", descriptions.head.title)
    } else if (key === "HEAD_SEPR") {
      descriptions.head.sepr = sheets[key];
    } else if (key === matchedKey + "_HEADER_TITLE") {
      descriptions.header.title = sheets[key];
    } else if (key === matchedKey + "_HEADER_CONTENTS") {
      descriptions.header.contents = sheets[key].split("\n");
    } else if (key === matchedKey + "_FOOTER_TITLE") {
      descriptions.footer.title = sheets[key];
    } else if (key === matchedKey + "_FOOTER_TOP_CONTENTS") {
      descriptions.footer.top.contents = sheets[key].split("\n");
    } else if (key === matchedKey + "_FOOTER_MIDDLE_TITLE") {
      descriptions.footer.middle.title = sheets[key];
    } else if (key === matchedKey + "_FOOTER_MIDDLE_CONTENTS") {
      descriptions.footer.middle.contents = sheets[key].split("\n");
    } else if (key === matchedKey + "_FOOTER_BOTTOM_CONTENTS") {
      descriptions.footer.bottom.contents = sheets[key].split("\n");
    }
  }

  // console.log("new descriptions: ", descriptions);
  // console.log("splited contents: ", sheets.DEFAULT_FOOTER_MIDDLE_CONTENTS.split("\n"));

  return descriptions;
  // return defaultDescriptions();
  // return formatDescriptions();
}

const HomeReducer = (state = HomeState, action) => {
  switch (action.type) {
    case "HOME_NEXT_PAGE":
      console.log("HOME_NEXT_PAGE before: ", state.pageIndex);
      state.pageIndex++;
      // More then max page
      // if(state.pageIndex >= state.thumbs.length / state.countPerPage) {
      //   state.pageIndex = state.thumbs.length / state.countPerPage;
      // }

      state.currentThumbs = state.thumbs.slice(
        (state.pageIndex - 1) * state.countPerPage,
        (state.pageIndex - 1) * state.countPerPage + state.countPerPage
      );
      console.log("HOME_NEXT_PAGE after: ", state.pageIndex);

      return {
        ...state,
      };
    case "HOME_PREVIOUS_PAGE":
      console.log("HOME_PREVIOUS_PAGE before: ", state.pageIndex);

      state.pageIndex--;
      if (state.pageIndex <= 0) {
        state.pageIndex = 1; // default
      }
      state.currentThumbs = state.thumbs.slice(
        (state.pageIndex - 1) * state.countPerPage,
        (state.pageIndex - 1) * state.countPerPage + state.countPerPage
      );
      console.log("HOME_PREVIOUS_PAGE after: ", state.pageIndex);

      return {
        ...state,
      };
    case "HOME_SELECT_PAGE":
      console.log("HOME_SELECT_PAGE before: ", state.pageIndex);

      state.pageIndex = action.index;
      state.currentThumbs = state.thumbs.slice(
        (state.pageIndex - 1) * state.countPerPage,
        (state.pageIndex - 1) * state.countPerPage + state.countPerPage
      );
      console.log("HOME_SELECT_PAGE after: ", state.pageIndex);

      return {
        ...state,
      };
    case "HOME_PARSE_API_RESPONSE":
      if (action.platform === StripChatApi.config.platform) {
        state.thumbs = state.thumbs.concat(
          StripChatApi.parseModels(action.response)
        );
      }

      if (action.platform === ChaturbateApi.config.platform) {
        state.thumbs = state.thumbs.concat(
          ChaturbateApi.parseModels(action.response)
        );
      }

      if(action.searchText.toLowerCase() !== "chinese" && action.searchText.toLowerCase() !== "china")
      {
        state.thumbs.shuffle();
      }

      state.pageIndex = 1;
      state.currentThumbs = state.thumbs.slice(
        (state.pageIndex - 1) * state.countPerPage,
        (state.pageIndex - 1) * state.countPerPage + state.countPerPage
      );

      state.control.isRefresh = false;
      return {
        ...state,
      };
    case "HOME_PARSE_GOOGLE_SHEETS_RESPONSE":
      let sheets = GoogleSheetApi.parse(action.response);
      console.log("sheets:", sheets);
      state.descriptions = parseDescriptions(sheets, action.searchText);
      state.control.isRefresh = false;
      return {
        ...state,
      };
    case "HOME_PARSE_GOOGLE_SHEETS_REQUEST_FAILED":
      state.descriptions = defaultDescriptions();
      state.control.isRefresh = false;
      return {
        ...state,
      };
    case "TOPBAR_SEARCH":
      state.control.isRefresh = true;
      return {
        ...state,
      };
    case "SIDEBAR_SWITCH_TAG":
      state.control.isRefresh = true;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default HomeReducer;
