const ThumbState = {
  
};

const ThumbReducer = (state = ThumbState, action) => {
  switch (action.type) {
    case "SET_MODE":
      return {
        ...state,
        mode: action.payload,
      };
    case "SET_COLOR":
      return {
        ...state,
        color: action.payload,
      };
    case "HOME_TEST":
      console.log("ThumbReducer.action: ", action.type, action.value)
      return {
        ...state
      }
    default:
      return state;
  }
};

export default ThumbReducer;
