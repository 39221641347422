import axios from "axios";
import TestImg from "../../assets/img/v2.png";

const g_account = {
  userId: "",
  // White Label
  wlDomain: "",
};

// Config
const config = {
  platform: "chaturbate",
  baseUrl: "https://chaturbate.com",
  account: g_account,
  models: {
    // model list
    path: function (limit, tag) {
      let p_limit = "";
      let p_tag = "";
      let p_gender = "&gender=f";
      if (limit !== "" && limit) {
        p_limit = `&limit=${limit}`;
      }
      if (tag !== "" && tag) {
        p_tag = `&tag=${tag}`;
      }

      let url = `/api/public/affiliates/onlinerooms/?wm=Tmmkp&client_ip=request_ip${p_gender}${p_limit}${p_tag}`;
      console.log(url);
      return url;
    },
  },
  iframe: {
    url: function (username) {
      return `https://chaturbate.com/in/?tour=9oGW&campaign=Tmmkp&track=embed&room=${username}&bgcolor=white`;
    },
  },
  model: {
    path: function (name) {
      return "";
    },
    url: function (name) {
      return "";
    },
    wlUrl: function (modelName, onlyIfOnline, sound) {
      return "";
    },
  },
  // Revshare
  goToTheRoom: {
    url: function (username) {
      return `https://chaturbate.com/in/?tour=YrCr&campaign=Tmmkp&track=default&room=${username}`;
    },
  },
  signup: {
    path: function () {
      return "";
    },
    url: function () {
      return "";
    },
    wlUrl: function () {
      // White Label
      return "";
    },
  },
};

// Request
const option = {
  baseURL: config.baseUrl,
};

const request = axios.create(option);

request.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.error("Chaturbate interceptors.request error:", error);
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    console.error("Chaturbate interceptors.response error:", error);
    return Promise.reject(error);
  }
);

// Parse
function parseModels(response) {
  console.log("Chaturbate api response: ", response);

  let models = [];

  if (!response) {
    return models;
  }

  if (!response.results) {
    return models;
  }

  if(response.results <= 0)
  {
    return models;
  }

  for (let i = 0; i < response.results.length; i++) {
    let model = response.results[i];

    // console.log("chaturbate model: ", model);

    let imgUrl = model.image_url_360x270;
    if (model.image_url_360x270 === "") {
      imgUrl = model.image_url;
      if (model.image_url === "") {
        continue;
      }
    }

    if (process.env.NODE_ENV === "development") {
      // imgUrl = TestImg;
    }

    models.push({
      platform: config.platform,
      img: imgUrl,
      views: model.num_users,
      favorited: model.num_followers,
      subject: model.room_subject,
      modelName: model.username,
      modelType: "type",
      // modelUrl: config.model.url(model.username),
      // modelUrl: config.model.wlUrl(model.username, false, true),
      modelIFrame: model.iframe_embed_revshare,
      tags: model.tags,
      languages: model.spoken_languages,
      birthday: model.birthday,
      isHd: model.is_hd,
      age: model.age,
      onlineSeconds: model.seconds_online,
      gender: model.gender,
      location: model.location,
      currentShow: model.current_show,
      isNew: model.is_new,
    });

    // break;
  }
  console.log("models: ", models);
  return models;
}

const ChaturbateApi = {
  request: request,
  config: config,
  parseModels: parseModels,
};

export default ChaturbateApi;
