import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';

// function Sidebar() {
//   return (
//     <ul className="sidebar navbar-nav">
//       <li className="nav-item active">
//         <a className="nav-link" href="/">
//           <i className="fas fa-fw fa-home"></i>
//           <span>Home</span>
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" href="model">
//           <i className="fas fa-fw fa-users"></i>
//           <span>Model</span>
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" href="single-channel.html">
//           <i className="fas fa-fw fa-user-alt"></i>
//           <span>Single Channel</span>
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" href="video-page.html">
//           <i className="fas fa-fw fa-video"></i>
//           <span>Video Page</span>
//         </a>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" href="upload-video.html">
//           <i className="fas fa-fw fa-cloud-upload-alt"></i>
//           <span>Upload Video</span>
//         </a>
//       </li>
//       <li className="nav-item dropdown">
//         <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//           <i className="fas fa-fw fa-folder"></i>
//           <span>Pages</span>
//         </a>
//         <div className="dropdown-menu">
//           <h6 className="dropdown-header">Login Screens:</h6>
//           <a className="dropdown-item" href="login.html">Login</a>
//           <a className="dropdown-item" href="register.html">Register</a>
//           <a className="dropdown-item" href="forgot-password.html">Forgot Password</a>
//           <div className="dropdown-divider"></div>
//           <h6 className="dropdown-header">Other Pages:</h6>
//           <a className="dropdown-item" href="404.html">404 Page</a>
//           <a className="dropdown-item" href="blank.html">Blank Page</a>
//         </div>
//       </li>
//       <li className="nav-item">
//         <a className="nav-link" href="history-page.html">
//           <i className="fas fa-fw fa-history"></i>
//           <span>History Page</span>
//         </a>
//       </li>
//       <li className="nav-item dropdown">
//         <a className="nav-link dropdown-toggle" href="categories.html" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//           <i className="fas fa-fw fa-list-alt"></i>
//           <span>Categories</span>
//         </a>
//         <div className="dropdown-menu">
//           <a className="dropdown-item" href="categories.html">Movie</a>
//           <a className="dropdown-item" href="categories.html">Music</a>
//           <a className="dropdown-item" href="categories.html">Television</a>
//         </div>
//       </li>
//       <li className="nav-item channel-sidebar-list">
//         <h6>SUBSCRIPTIONS</h6>
//         <ul>
//           <li>
//             <a href="subscriptions.html">
//               <img className="img-fluid" alt="" src={YourLifeImg}></img> YourLife
//             </a>
//           </li>
//           <li>
//             <a href="subscriptions.html">
//               <img className="img-fluid" alt="" src="../../../public/img/s2.png"></img> Unboxing  <span className="badge badge-warning">2</span>
//             </a>
//           </li>
//           <li>
//             <a href="subscriptions.html">
//               <img className="img-fluid" alt="" src="../../../public/img/s3.png"></img> Product / Service
//             </a>
//           </li>
//           <li>
//             <a href="subscriptions.html">
//               <img className="img-fluid" alt="" src="../../../public/img/s4.png"></img>  Gaming
//             </a>
//           </li>
//         </ul>
//       </li>
//     </ul>
//   )
// }

function matchBarIndex(props, location) {
  for (let i = 0; i < props.sidebar.bars.length; i++) {
    console.log("location:", location.pathname, props.sidebar.bars[i].path);

    // if(location.pathname.indexOf(props.sidebar.bars[i].path) >= 0)
    if (location.pathname === props.sidebar.bars[i].path) {
      props.sidebar.activeBar = i;
      console.log("match bar index:", props.sidebar.activeBar)
      return;
    }
  }
}

function Sidebar(props) {
  // console.log("Sidebar props:", props)
  const navigate = useNavigate();
  let location = useLocation();

  function onClickBar(index) {
    console.log("onClickBar: ", index)
    props.switchBar(index);
    navigate(props.sidebar.bars[index].path);
  }

  function onClickTag(name) {
    console.log("onClickTag: ", name, window.location.host);
    props.switchTag(name);
    // navigate("/?search=" + name, {
    //   state: {
    //     searchText: name
    //   }
    // })

    window.history.pushState({}, "", window.location.herf); // add back button.
    const naviURL = `${document.location.origin}/?search=${name}`

    console.log("naviURL: ", naviURL);
    window.location.replace(naviURL);
  }

  // matchBarIndex(props, location);

  return (
    <ul className="sidebar navbar-nav">
      {
        props.sidebar.bars.map((bar, barIndex) => {
          return (
            <div key={barIndex}>
              <li className="channel-sidebar-list">
                <h6>{bar.category}</h6>
              </li>
              {
                bar.tags.map((tag, index) => {
                  return (
                    <li className={`nav-item`} key={index}>
                      <div className="nav-link" onClick={() => { onClickTag(tag.name) }} >
                        {/* <i className={`fas fa-fw ${tag.icon}`}></i> */}
                        <span>{tag.title}</span>
                      </div>
                    </li>
                  )
                })
              }
            </div>
          )
        })
      }
    </ul>
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    switchBar(index) {
      dispatch({ type: "SIDEBAR_SWITCH", index: index })
    },
    switchTag(name) {
      dispatch({ type: "SIDEBAR_SWITCH_TAG", name: name })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);