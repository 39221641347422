import axios from "axios";
import TestImg from "../../assets/img/v1.png";

const g_account = {
  userId: "8b42e3e3fb3375de7e77896b1472ce7e082e48905348a8c20021370945426d84",
  // White Label
  wlDomain: "live.herecams.com",
};

// Config
const config = {
  platform: "stripchat",
  baseUrl: "https://go.xlviiirdr.com",
  account: g_account,
  models: {
    // model list
    path: function (limit, tag) {
      let p_limit = "";
      let p_tag = "";
      if (limit !== "" && limit) {
        p_limit = `?limit=${limit}`;
      }
      if (tag !== "" && tag) {
        p_tag = `&tag=girls/${tag}`;
      }
      let url = `/api/models${p_limit}${p_tag}`;
      console.log(url);
      return url;
    },
  },
  model: {
    path: function (name) {
      return `/?userId=${g_account.userId}&path=/cams/${name}&showModal=signup`;
    },
    url: function (name) {
      let u = `${config.baseUrl}/?userId=${g_account.userId}&path=/cams/${name}&showModal=signup`;
      // console.log("model url:", u);
      return u;
    },
    wlUrl: function (modelName, onlyIfOnline, sound) {
      let argSound = "";
      let argModel = "";
      if (sound) {
        argSound = "&sound=on";
      }

      if (onlyIfOnline) {
        argModel = "&onlineModels=" + modelName;
      } else {
        argModel = "&path=/" + modelName;
      }

      // White Label
      let u = `${config.baseUrl}?userId=${g_account.userId}&targetDomain=${g_account.wlDomain}${argModel}${argSound}`;
      // console.log("model wl url:", u);
      return u;
    },
  },
  goToTheRoom: {
    path: function () {
      return `/api/goToTheRoom?userId=${g_account.userId}&tag=girls%2Fchinese&showModal=signup`;
    },
  },
  signup: {
    path: function () {
      return `/api/goToTheRoom?userId=${g_account.userId}&tag=girls%2Fchinese&showModal=signup`;
    },
    url: function () {
      return `${config.baseUrl}/?userId=${g_account.userId}&tag=girls%2Fchinese&showModal=signup`;
    },
    wlUrl: function () {
      // White Label
      return `https://${g_account.wlDomain}/signup/user?realDomain=go.xlviiirdr.com&sound=off&stripbotVariation=NullWidget`;
    },
  },
};

// Request
const option = {
  baseURL: config.baseUrl,
};

const request = axios.create(option);

request.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Parse
function parseModels(response) {
  if (response.count <= 0) {
    return [];
  }

  let models = [];
  for (let i = 0; i < response.count; i++) {
    let model = response.models[i];
    // console.log("avatar:", model.avatarUrl);
    // let imgUrl = model.avatarUrl;
    let imgUrl = model.snapshotUrl;
    if (model.snapshotUrl === "") {
      imgUrl = model.avatarUrl;
      if (model.avatarUrl === "") {
        // console.log(model);
        imgUrl = model.previewUrlThumbSmall;
        continue;
      }
    }

    if(process.env.NODE_ENV === "development")
    {
      // imgUrl = TestImg
    }
    
    models.push({
      platform: config.platform,
      img: imgUrl,
      views: model.viewersCount,
      favorited: model.favoritedCount,
      subject: model.goalMessage,
      modelName: model.username,
      modelType: "type",
      // modelUrl: config.model.url(model.username),
      modelUrl: config.model.wlUrl(model.username, false, true),
    });
  }
  // console.log("models: ", models);
  return models;
}

const StripChatApi = {
  request: request,
  config: config,
  parseModels: parseModels,
};

export default StripChatApi;
