import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import StripChatApi from "../apis/StripChat/StripChatApi"
import MobileSearch from "../components/content/MobileSearch";
import RelatedThumb from '../components/thumb/RelatedThumb';

import LogoImg from "../assets/camsites/stripchat/logo.svg";
import "./model.css"


function refreshElement() {
  document.getElementById('player-container').remove();
  let e = document.createElement('div');
  e.className = 'model-player';
  e.style = "height:760px";
  e.id = "player-container"

  document.getElementById('player-placeholder').appendChild(e)
}

function getPlayer(modelName) {
  // refreshElement();
  const player = new window.StripchatPlayer({
    modelName: modelName,
    userId: StripChatApi.config.account.userId,
    strict: 1,
    showModal: 'signup',
    autoplay: 'all',
    targetDomain: StripChatApi.config.account.wlDomain,
    sound: "on"
  });

  player.mount(document.getElementById('player-container')).then((app) => {
    app.setParams({ modelName: modelName }).then(() => {
      // OK
      console.log("Player.setParams ok, height:", document.getElementById('player-container').offsetHeight);

    }).catch(() => {
      console.log("catch");
      // Model must be perform private show right now, try later
    });

    app.destroy();
  })
}

function getChat(modelName) {
  const chat = new window.StripchatChat({
    modelName: modelName,
    // ...any other Chat creative params
  });

  chat.mount(document.getElementById('chat-container')).then((app) => {
    app.setParams({ modelName: modelName }).then(() => {
      // OK
      console.log("Chat.setParams ok");

    }).catch(() => {
      // Model must be perform private show right now, try later
    });

    app.destroy();
  })
}

function ModelProfile({ props, location }) {
  // console.log("ModelProfile: ", props.model.languages)

  return (
    <div className="single-video-info-content box mb-3" style={{ marginTop: "10px" }}>
      <div className='row'>
        <div className="col-xxl-3  col-12 model-signup">
          <div className='model-signup-background' style={{ backgroundColor: "#A32831" }}>
            <div className='model-signup-container'>
              <img src={LogoImg}></img>
              <p style={{ color: "#FFFFFF" }}>
                Get your free Stripchat account to watch fully nude models with no credit card needed. You can also favorite models and get entered into a 50 free tokens giveaway! </p>
              <a href={props.model.signupUrl}>Sign Up Here</a>
            </div>
          </div>
        </div>
        <div className="col-xxl-5 col-xl-7 col-12 model-stats">
          <h6>ABOUT ME</h6>
          <div className='model-stats-background'>
            <span>{location.state.modelName} doesn't have a bio page yet. We are working to get one for you.
              Until then, you can check out this model's photos by clicking the photos button above.</span>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-5 col-12 model-stats">
          <h6>MODEL'S STATS</h6>
          <ul className='model-stats-background'>
            <li><span>Nickname</span>{location.state.modelName}</li>
            <li><span>Tags</span>
              <span className="model-tag">
                {
                  props.model.tags.map((tag, index) => {
                    return (
                      <a href={'/?search=' + tag} className='tag-danger' key={index}>#{tag}</a>
                    )
                  })
                }
              </span>
            </li>
            {/* <li><span>Location</span>{location.state.modelName}</li> */}
            <li><span>Languages</span>{props.model.languages.join(", ")}</li>
            <li><span>Last Live Show</span>{props.model.lastLiveShow}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function Model(props) {
  console.log("Model start");

  props.model.platform = StripChatApi.config.platform;

  const playerRef = React.useRef(null);

  // const location = useLocation();
  // console.log("Model location:", location);
  const [params] = useSearchParams();
  const location = {
    state: {
      modelName: params.getAll("m")[0]
    }
  }


  const [size, setSize] = React.useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  })

  const onResize = React.useCallback(() => {
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    })
    playerRef.current.style.height = playerRef.current.clientWidth * 0.565 + "px"

    // console.log("size:",size.width, size.height)
    if (size.width < 768 && !props.model.control.isHidedSidebar) {
      props.model.control.isHidedSidebar = true;
      // console.log("toggleSidebar:", window.toggleSidebar)
      window.hideSidebar();
    }
  })

  React.useEffect(() => {

    if (props.model.control.isRefreshPlayer) {
      props.model.control.isRefreshPlayer = false;

      if (process.env.NODE_ENV === "development" && 0) {

      }
      else {
        getPlayer(location.state.modelName)
        onResize();
      }

    }

    // if (props.model.control.isRefreshChat) {
    //   props.model.control.isRefreshChat = false;
    //   getChat(location.state.modelName);
    // }

    window.addEventListener("resize", onResize);
    return (() => {
      window.removeEventListener("resize", onResize);
    })
  })

  return (
    <>
      <MobileSearch />
      <div className='row'>
        <div className="col-md-12">
          <div className="main-title">
            <h6>{location.state.modelName}</h6>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <a className='model-out' href={props.model.signupUrl} rel='nofollow' target="_blank">enter free chat here</a>
        </div>
        <div className='col-12'>
          <div ref={playerRef} id="player-container" className='model-player' ></div>
          {/* <div id="player-container" className='model-player' ref={playerRef} style={{ height: playerHeight, width: playerWidth }}></div> */}
        </div>
        {/* We don't need the Chat widget.
          <div className='col-xl-4 col-sm-12' >
          <div id="chat-container" className='chat-player' style={{ height: "600px"}}></div>
        </div> */}
      </div>
      <ModelProfile props={props} location={location} />

      <div className='row'>
        <div className="col-md-12">
          <div className="main-title">
            {/* <DropdownMenu /> */}
            <h6>RELATED CAMS</h6>
          </div>
        </div>

        <RelatedThumb {...props} />
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    parseApiResponse(response) {
      dispatch({ type: "MODEL_", response: response })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Model);