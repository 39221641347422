import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom';

import Thumb from '../components/thumb/Thumb'
import Pagination from '../components/content/Pagination'

import Channel from "../components/content/Channel";
import Carousel from "../components/content/Carousel";
import MobileSearch from "../components/content/MobileSearch";

import StripChatApi from '../apis/StripChat/StripChatApi'
import ChaturbateApi from '../apis/Chaturbate/ChaturbateApi'
import GoogleSheetApi from "../apis/Google/GoogleSheetApi"

import "./home.css"

function getModels(props) {
  StripChatApi.request.get(StripChatApi.config.models.path(
    props.home.countOfGetModels,
    props.topbar.searchText
  )).then(function (response) {
    console.log("StripChatApi models.response:", response);
    props.parseApiResponse(StripChatApi.config.platform, response, props.topbar.searchText);
  }).catch(function (error) {
    // props.googleSheetRequestFailed(error);
  })

  if (props.topbar.searchText.toLowerCase() !== "chinese" && props.topbar.searchText.toLowerCase() !== "china")
  {
    ChaturbateApi.request.get(ChaturbateApi.config.models.path(
      props.home.countOfGetModels,
      props.topbar.searchText
    )).then(function (response) {
      console.log("ChaturbateApi models.response:", response);
      props.parseApiResponse(ChaturbateApi.config.platform, response, props.topbar.searchText);
    }).catch(function (error){
      console.log("ChaturbateApi error: ", error);
      // console.log(error);
    })
  }
  else{
    // console.log("is chinese, no chaturbate")
  }

}

function getDescriptions(props) {
  GoogleSheetApi.request.get(GoogleSheetApi.config.path(
    "17xiLFzncjy4g-Gjk-3vR9RN4bpPU-jYWaiF2Zjb5Stk",
    true,
    "A1:B50"
  )).then(function (response) {
    console.log("descriptions.response:", response);
    props.parseGoogleSheetResponse(response, props.topbar.searchText);
    props.updateSidebarBars(GoogleSheetApi.sheets())

    if (props.home.descriptions.head.title) {
      document.title = props.home.descriptions.head.title;
    }
  }).catch(function (error) {
    props.googleSheetRequestFailed(error);
  })
}

function Header(props) {
  // console.log("Header:", props)
  return (
    <div className="main-title">
      <h1>{props.home.descriptions.header.title}</h1>
      {
        props.home.descriptions.header.contents.map((content, index) => {
          return (
            <div key={index}>
              {content}
            </div>
          )
        })
      }
    </div>
  )
}

function DropdownMenu() {
  return (
    <div className="btn-group float-right right-action">
      <a href="#" className="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Sort by <i className="fa fa-caret-down" aria-hidden="true"></i>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        <a className="dropdown-item" href="/"><i className="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
        <a className="dropdown-item" href="/"><i className="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
        <a className="dropdown-item" href="/"><i className="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
      </div>
    </div>
  )
}

function ThumbHeader(props) {
  console.log("Home ThumbHeader:", props)
  let title = props.topbar.searchText;
  if (props.topbar.searchText === "") {
    title = "LIVE CAMS";
  }
  else {
    title = title.toUpperCase() + " CAMS";
  }
  return (
    <div className="col-md-12">
      <div className="main-title">
        {/* <DropdownMenu /> */}
        <h6>{title}</h6>
      </div>
    </div>
  )
}

function Footer(props) {
  return (
    <>
      <div className='osahan-title'>{props.home.descriptions.footer.title}</div>
      <br></br>
      {
        props.home.descriptions.footer.top.contents.map((content, index) => {
          return (
            <div key={index}>
              {content}
            </div>
          )
        })
      }
      <p>{props.home.descriptions.footer.middle.title}</p>
      <ul className='ul-disc' >
        {
          props.home.descriptions.footer.middle.contents.map((category, index) => {
            return (
              <li key={index}>{category}</li>
            )
          })
        }
      </ul>
      <br></br>
      {
        props.home.descriptions.footer.bottom.contents.map((content, index) => {
          return (
            <div key={ index }>
              {content}
            </div>
          )
        })
      }
    </>
  )
}

function Home(props) {
  console.log("Home start: props=", props);

  // console.log("Home doenv:", process.env);

  // const mountRef = React.useRef(0);
  let location = useLocation();
  const [searchParams] = useSearchParams();


  React.useEffect(() => {
    console.log("Home mounted");
    // if (mountRef.current <= 0) {
    // mountRef.current++;
    if (props.home.control.isRefresh) {
      props.home.control.isRefresh = false;
      if (location.state) {
        props.topbar.searchText = location.state.searchText
      }

      if (searchParams) {
        const params = searchParams.getAll("search");
        console.log("search params:", params);
        if (params && params.length > 0) {
          props.topbar.searchText = params[0]
        }
        // props.switchDescriptions();
      }
      else {
      }
      getModels(props);
      getDescriptions(props);
      
    }
  })

  return (
    <>
      <MobileSearch />
      <Header {...props} />
      <hr></hr>
      {/* <Carousel/> */}
      {/* <hr></hr> */}
      <div className="video-block section-padding">
        <div className="row">
          <ThumbHeader {...props} />
          <Thumb />
        </div>
      </div>
      <Pagination />
      <hr></hr>
      <Footer {...props} />
      <br></br>
      {/* <hr className="mt-0"></hr> */}
      {/* <Channel /> */}
    </>
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    parseApiResponse(platform, response, searchText) {
      dispatch({ type: "HOME_PARSE_API_RESPONSE", platform: platform, response: response, searchText: searchText })
    },
    parseGoogleSheetResponse(response, searchText) {
      dispatch({ type: "HOME_PARSE_GOOGLE_SHEETS_RESPONSE", response: response, searchText: searchText})
    },
    googleSheetRequestFailed(error) {
      dispatch({ type: "HOME_PARSE_GOOGLE_SHEETS_REQUEST_FAILED", error: error})
    },
    updateSidebarBars(sheets){
      // console.log("home side bar HOME_UPDATE_SIDEBAR_TAGS");
      dispatch({ type: "HOME_UPDATE_SIDEBAR_BARS", sheets: sheets})
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);