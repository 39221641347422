import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function MobileSearch(props) {

	const [text, setText] = React.useState(props.topbar.searchText);
	const navigate = useNavigate();

	function onChange(element) {
		console.log("mobile search on change keycode: ", element.keyCode);
		setText(element.target.value);
	}

	function onKeyDown(element) {
		if (element.keyCode === 13) { // Enter press
			onSearch();
		}
	}

	function onSearch() {
		console.log("onSearch: ", text);
		props.search(text);
		navigate("/?search=" + text, {
			state: {
				searchText: text
			}
		})
	}

	return (
		<form className="top-mobile-search" action="/">
			<div className="row">
				<div className="col-md-12">
					<div className="mobile-search">
						<div className="input-group">
							{/* <input type="text" placeholder="Search for #tag or model..." className="form-control" value={text} onChange={onChange} onKeyDown={onKeyDown}></input> */}
							<input type="text" placeholder="Search for #tag or model..." className="form-control" name="search"></input>
							<div className="input-group-append">
								{/* <button type="button" className="btn btn-dark" onClick={onSearch}><i className="fas fa-search"></i></button> */}
								<button type="submit" className="btn" ><i className="fas fa-search"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	)
}

function mapStateToProps(state) {
	// console.log("App.mapStateToProps: ", state);
	return {
		...state,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		search(text) {
			dispatch({ type: "TOPBAR_SEARCH", text: text });
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileSearch);
