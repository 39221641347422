import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ChaturbateApi from '../apis/Chaturbate/ChaturbateApi';
import MobileSearch from "../components/content/MobileSearch";
import RelatedThumb from '../components/thumb/RelatedThumb';

import LogoImg from "../assets/camsites/chaturbate/logo.svg";
import "./model.css"

const PLAYER_RATION_DESKTOP = 0.565;
const PLAYER_RATION_MOBILEW = 1.11;

function ModelProfile({ props, location }) {
  // console.log("ModelProfile: ", props.model.languages)

  return (
    <div className="single-video-info-content box mb-3" style={{ marginTop: "10px" }}>
      <div className='row'>
        <div className="col-xxl-3  col-12 model-signup">
          <div className='model-signup-background' >
            <div className='model-signup-container'>
              <img src={LogoImg}></img>
              <p >Join Chaturbate for free (no credit card or Email required) and start watching and chatting with nude cam models immediately! What's more, upgrade your account to get 200 free tokens, remove ads and unlock PM's with your favorite performers!
              </p>
              <a href={ChaturbateApi.config.goToTheRoom.url(location.state.modelName)}>Sign Up Here</a>
            </div>
          </div>
        </div>
        <div className="col-xxl-5 col-xl-7 col-12 model-stats">
          <h6>ABOUT ME</h6>
          <div className='model-stats-background'>
            <span>{location.state.modelName} doesn't have a bio page yet. We are working to get one for you.
              Until then, you can check out this model's photos by clicking the photos button above.</span>
          </div>
        </div>
        <div className="col-xxl-4 col-xl-5 col-12 model-stats">
          <h6>MODEL'S STATS</h6>
          <ul className='model-stats-background'>
            <li><span>Nickname</span>{location.state.modelName}</li>
            <li><span>Tags</span>
              <span className="model-tag">
                {
                  props.model.tags.map((tag, index) => {
                    return (
                      <a href={'/?search=' + tag} className='tag-danger' key={index}>#{tag}</a>
                    )
                  })
                }
              </span>
            </li>
            {/* <li><span>Location</span>{location.state.modelName}</li> */}
            <li><span>Languages</span>{props.model.languages.join(", ")}</li>
            <li><span>Last Live Show</span>{props.model.lastLiveShow}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function ChaturbateModel(props) {
  console.log("ChaturbateModel start");
  props.model.platform = ChaturbateApi.config.platform;

  const [params] = useSearchParams();
  const location = {
    state: {
      modelName: params.getAll("m")[0]
    }
  }

  const playerRef = React.useRef(null);
  const onResize = React.useCallback(() => {
    if (document.documentElement.clientWidth < 768 && !props.model.control.isHidedSidebar) {
      props.model.control.isHidedSidebar = true;
      window.hideSidebar();
      playerRef.current.style.height = playerRef.current.clientWidth * PLAYER_RATION_MOBILEW + "px"
    }
    else {
      playerRef.current.style.height = playerRef.current.clientWidth * PLAYER_RATION_DESKTOP + "px"
    }
  })

  let initResize = false;
  React.useEffect(() => {
    if (!initResize) {
      onResize();
      initResize = true;
    }
    window.addEventListener("resize", onResize);
    return (() => {
      window.removeEventListener("resize", onResize);
    })
  })

  return (
    <>
      <MobileSearch />
      <div className='row'>
        <div className="col-md-12">
          <div className="main-title">
            <h6>{location.state.modelName}</h6>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <a className='model-out' href={ChaturbateApi.config.goToTheRoom.url(location.state.modelName)} rel='nofollow' target="_blank">free chat with sound</a>
        </div>
        <div className='col-12' >
          {/* <div dangerouslySetInnerHTML={{ __html: iframe }}></div> */}
          <iframe id="cbiframe" ref={playerRef} src={ChaturbateApi.config.iframe.url(location.state.modelName)} style={{ border: "none", width: "100%" }}></iframe>
        </div>
      </div>
      <ModelProfile props={props} location={location} />
      <div className='row'>
        <div className="col-md-12">
          <div className="main-title">
            {/* <DropdownMenu /> */}
            <h6>RELATED CAMS</h6>
          </div>
        </div>

        <RelatedThumb {...props} />
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    parseApiResponse(response) {
      dispatch({ type: "MODEL_", response: response })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChaturbateModel);