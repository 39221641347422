import api from "./ApiReducer"
import thumb from "./ThumbReducer"
import home from "./HomeReducer"
import sidebar from "./SidebarReducer"
import topbar from "./TopbarReducer"
import model from "./ModelReducer";
import { combineReducers } from "redux"

const rootReducer = combineReducers({thumb, api, home, sidebar, topbar, model})

export default rootReducer