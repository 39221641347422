import StripChatApi from "../../apis/StripChat/StripChatApi";


const ApiState = {
  stripchat: {
    modelsResponse: {},
  },
  thumbs: [  
    // {
    //   img: "",
    //   views: "",
    //   title: "",
    //   modelName: "",
    // },
  ],
};


const ApiReducer = (state = ApiState, action) => {
  switch (action.type) {
    case "PARSE_API_RESPONSE":
      console.log("ApiReducer.PARSE_API_RESPONSE: ", action.type, action.value);
      return {
        ...state,
        thumbs: StripChatApi.parseModels(action.value)
      };
    default:
      return state;
  }
};

export default ApiReducer;
