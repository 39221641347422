import StripChatApi from "../../apis/StripChat/StripChatApi";
import LogoImg from "../../assets/camsites/stripchat/logo.svg";



const ModelState = {
  platform: "",
  name: "pinkie_princess",

  tags: [
    "GIRLS",
    "69",
    "AHEGAO",
    "ASIAN",
    "ASIAN YOUNG",
    "BIG ASS",
    "BIG BOOBS",
    "BRUNETTES",
    "BRUNETTES YOUNG",
    "CHINESE",
    "DEEPTHROAT",
    "DILDO OR VIBRATOR",
    "DIRTY TALK",
    "DOGGY STYLE",
    "EROTIC DANCE",
    "FINGERING",
    "FLASHING",
    "HD",
    "INTERACTIVE TOYS",
    "INTERACTIVE TOYS YOUNG",
    "LOVENSE",
    "MEDIUM",
    "MOST AFFORDABLE",
    "NIPPLE TOYS",
    "OFFICE",
    "OIL SHOW",
    "SEX TOYS",
    "SPANKING PADDLING",
    "SQUIRT",
    "STRIPTEASE",
    "TITTY FUCK",
    "TOPLESS",
    "TOPLESS ASIAN",
    "TOPLESS YOUNG",
    "TWERK",
    "UPSKIRT",
    "YOUNG",
    "SBIGASS",
    "VOYEUR",
    "BIG",
    "AVERAGE",
    "DARK",
  ],
  languages: ["En"],
  lastLiveShow: "Currently Live",
  signupUrl: StripChatApi.config.signup.wlUrl(),
  logoImg: LogoImg,
  //
  control: {
    isRefreshPlayer: true,
    isRefreshChat: true,
    isHidedSidebar: false,
  },
};

const ModelReducer = (state = ModelState, action) => {
  switch (action.type) {
    case "THUMB_CLICK_MODEL":
      state.name = action.name;
      state.isRefreshPlayer = true;
      state.isRefreshChat = true;
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default ModelReducer;
