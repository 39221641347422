import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import StripChatModel from "./pages/StripChatModel";
import ChaturbateModel from "./pages/ChaturbateModel";
import Gdpr from "./pages/Gdpr";
import Contact from "./pages/Contact";
import Dmca from "./pages/Dmca";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
// import Error404 from "./pages/Error404";

import Topbar from "./components/topbar/Topbar";
import Sidebar from "./components/sidebar/Sidebar";
import Footer from "./components/footer/Footer";

function SorollToTopButton() {
  return (
    <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
    </a>
  );
}

function App(props) {
  console.log("App start: propos=", props);

  return (
    <div id="page-top">
      <BrowserRouter>
        <Topbar />
        <Sidebar />
        <div id="wrapper">
          <div id="content-wrapper">
            <div className="container-fluid pb-0">
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/stripchat/*" element={<StripChatModel />}></Route>
                <Route
                  path="/chaturbate/*"
                  element={<ChaturbateModel />}
                ></Route>
                <Route path="/gdpr" element={<Gdpr />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/privacy" element={<Privacy />}></Route>
                <Route path="/terms" element={<Terms />}></Route>
                <Route path="/dmca" element={<Dmca />}></Route>
                <Route path="*" element={<Home />}></Route>
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
      <SorollToTopButton />
    </div>
  );
}

function mapStateToProps(state) {
  // console.log("App.mapStateToProps: ", state);
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
