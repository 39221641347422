import React from 'react'
import { connect } from "react-redux";


function Dmca(props) {
  return (
    <>
      <h2>DMCA Compaint</h2>
      <div className="single-video-info-content box mb-3">
        <p>First and foremost, HereCams does NOT steal your content. We promote your steam to a wider audience and bring you fans who pay you. Do not mistakingly lump us in with sites that steal your videos and photos without promoting you and adding value to your bottom line. We do NOT host your video. It is a live stream of your show that your cam company provided to us because they want us to help you to make more money.</p>

        <p>All content on herecams.com (SITE) is used pursuant to the terms of service (TOS) of the respective cams services of the models working with them. We fully comply with Digital Millennium Copyright Act.</p>

        <p>If you are a cams performer who's content is on our website, or work in the service of such persons, be aware that per the TOS of your partnering webcam service, we are within our rights to use your content to promote your performances and bring you a wider audience.</p>

        <p>However, if you no longer wish to receive our promotion, and you have stopped camming, you can submit the form below and we will remove your content. Also keep in mind that your service provider furnished the promotional materials to us on your behalf. You will want to inform them that you no longer wish to be included in promotional material that is made available to their affiliates.</p>

        <p>Be sure to include in the body of your message:</p>
        <ul className='ul-disc' >
          <li>description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
          <li>a description of where the material that you claim is infringing is located on the SITE (preferably including specific url's associated with the material);</li>
          <li>your address, telephone number, and email address;</li>
          <li>a statement by you that you have a good faith belief that the disputed use is not authorised by the copyright owner, its agent, or the law; and,</li>
          <li>a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorised to act on the copyright or intellectual property owner's behalf;</li>
          <li>you may be required to show further proof that you are indeed the owner of the allegedly infringed work(s), or working on their behalf.</li>
        </ul>
        <br></br>
        <a href="mailto:info@herecams.com" style={{ color: "#1b98d5"}}>info@herecams.com</a>
        <br></br>

        <h5><strong>TAKE DOWN PROCEDURE</strong></h5>
        <p>The SITE implements the following "notification and takedown" procedure upon receipt of any notification of claimed copyright infringement. The SITE reserves the right at any time to disable access to, or remove any material or activity accessible on or from any SITE or any Materials claimed to be infringing or based on facts or circumstances from which infringing activity is apparent. Agents of the SITE may find the need to contact the party, or parties, serving the notification for further investigation and to ensure that the party, or parties, serving the notification are indeed acting in good faith and are the intellectual property owner(s), or are authorised to work on their behalf.</p>

        <p>If, however, it can be shown that we are within our rights to use your content and you are still actively camming, we may decide to retain your content until otherwise informed to remove it by the cam service you are working with.</p>

        <p>All notices must be written in English and follow the above mentioned guidelines to be deemed valid.</p>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dmca);