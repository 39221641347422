import React from 'react'
import { connect } from 'react-redux'

function PreviousButton(props) {
  // console.log("PreviousButton props:", props);

  if (props.home.pageIndex <= 1) {
    return (
      <li className="page-item disabled">
        <a className="page-link" onClick={props.previousPage} >Previous</a>
      </li>
    )
  }
  else {
    return (
      <li className="page-item">
        <a className="page-link" onClick={props.previousPage} >Previous</a>
      </li>
    )
  }
}

function NextButton(props) {
  if (props.home.pageIndex >= props.home.thumbs.length / props.home.countPerPage) {
    return (
      <li className="page-item disabled">
        <a className="page-link" onClick={props.nextPage} >Next</a>
      </li>
    )
  }
  else {
    return (
      <li className="page-item">
        <a className="page-link" onClick={props.nextPage} >Next</a>
      </li>
    )
  }
}

function Index(props) {
  console.log("Index props:", props);

  let doms = [];
  for (let i = props.home.pageIndex - props.home.paginationDeviation; i <= props.home.pageIndex + props.home.paginationDeviation; i++) {
    if (i > 0 && i <= Math.ceil(props.home.thumbs.length / props.home.countPerPage)) {
      if (i == props.home.pageIndex) {
        doms.push(
          <li className="page-item active" key={props.home.pageIndex} ><a className="page-link" onClick={() => { props.selectPage(props.home.pageIndex) }}>{props.home.pageIndex}</a></li>
        )
      }
      else {
        doms.push(
          <li className="page-item" key={i}><a className="page-link" onClick={() => { props.selectPage(i) }}>{i}</a></li>
        )
      }
    }
  }
  return doms;
}

function Pagination(props) {
  console.log("pagination props:", props);
  let lastIndex = Math.ceil(props.home.thumbs.length / props.home.countPerPage);

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center pagination-sm mb-4">
        <li className="page-item" >
          <a className="page-link" onClick={() => { props.selectPage(1) }}> &lt;&lt;</a>
        </li>
        <PreviousButton {...props} />
        <Index {...props} />
        <NextButton {...props} />
        <li className="page-item" >
          <a className="page-link" onClick={() => { props.selectPage(lastIndex) }}>&gt;&gt;</a>
        </li>
      </ul>
    </nav>
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextPage() {
      console.log("Next Page:");
      dispatch({ type: "HOME_NEXT_PAGE" });
    },
    previousPage() {
      console.log("Previous Page:");
      dispatch({ type: "HOME_PREVIOUS_PAGE" });
    },
    selectPage(index) {
      console.log("select page: ", index);
      dispatch({ type: "HOME_SELECT_PAGE", index: index });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);