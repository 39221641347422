import React from 'react'
import { connect } from "react-redux";


function Contact(props){
  return (
    <>
      <h3>CONTACT - HereCams</h3>
      <div className="single-video-info-content box mb-3">
        <h5><strong>CUSTOMER SERVICE AND OTHER INQUIRIES</strong></h5>
        <p>Do you need customer service, or would you like to explore advertising opportunities with us? Please use the form below to get into contact with us and we will reply via Email within 24 hours. If you do not hear from us within 24 hours, check your spam box.</p>

        <p>If you are interested in becoming a model see this page.</p>

        <p>If you are making a DMCA complaint or inquiry use this page.</p>

        <a href="mailto:info@herecams.com" style={{ color: "#1b98d5" }}>info@herecams.com</a>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);