import React from 'react'
import LogoImg from "../../assets/img/logo200x60.png"
import "./footer.css"

function Footer() {

  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="sticky-footer">
      {/* <div className="container"> */}
      <div className="row no-gutters align-items-start">
        <div className="col-lg-6 col-sm-6">
          <a href="/"><img alt="" src={LogoImg}></img></a>
          <br></br>
          <a >Copyright © HereCams.com 2021 - {currentYear}</a>
          <br></br>
          <a href='/contact' className='gray-text'>Contact HereCams</a>&nbsp;-&nbsp;
          <a href='/dmca' className='gray-text'>DMCA Complaint</a>&nbsp;-&nbsp;
          <a href='/privacy' className='gray-text'>Privacy Policy</a>&nbsp;-&nbsp;
          <a href='/terms' className='gray-text'>Terms and Conditions</a>&nbsp;-&nbsp;
          <a href='/gdpr' className='gray-text'>GDPR</a>
        </div>
        <div className="col-lg-6 col-sm-6">
          <h6>Free Cams</h6>
          <ul>
            <li><a href="/" className='gray-text' >Home</a></li>
          </ul>
        </div>
        {/* <div className="col-lg-6 col-sm-6 text-right">
            <div className="app">
              <a href="#"><img alt="" src="%PUBLIC_URL%/img/google.png"></img></a> 
             <a href="#"><img alt="" src="%PUBLIC_URL%/img/apple.png"></img></a> 
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </footer >
  )
}

export default Footer
