import React from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom";
import StripChatApi from '../../apis/StripChat/StripChatApi';
import ChaturbateApi from '../../apis/Chaturbate/ChaturbateApi';

import "./thumb.css"

function getModels(props) {
  if (props.model.platform === StripChatApi.config.platform)
  {
    StripChatApi.request.get(StripChatApi.config.models.path(
      12,
      ""
    )).then(function (response) {
      console.log("StripChatApi models.response:", response);
      props.parseApiResponse(StripChatApi.config.platform, response, "");
    }).catch(function (error) {
      // props.googleSheetRequestFailed(error);
    })
  }
  else if(props.model.platform === ChaturbateApi.config.platform)
  {
    ChaturbateApi.request.get(ChaturbateApi.config.models.path(
      12,
      ""
    )).then(function (response) {
      console.log("ChaturbateApi models.response:", response);
      props.parseApiResponse(ChaturbateApi.config.platform, response, "");
    }).catch(function (error) {
      // console.error("ChaturbateApi error: ", error);
      // console.log(error);
    })

  }

  // ChaturbateApi.request.get(ChaturbateApi.config.models.path(
  //   props.home.countOfGetModels,
  //   props.topbar.searchText
  // )).then(function (response) {
  //   console.log("ChaturbateApi models.response:", response);
  //   props.parseApiResponse(ChaturbateApi.config.platform, response);
  // }).catch(function (error) {
  //   // console.log("ChaturbateApi error: ", error);
  //   // console.log(error);
  // })

}

function subjectPretty(subject) {
  if (subject) {
    if (subject.length >= 50) {
      return subject.substr(0, 50) + "..."
    }
    else {
      return subject.substr(0, 50)
    }
  }
  return subject;
}

function RelatedThumb(props) {
  console.log("RelatedThumb start: props:", props);
  const navigate = useNavigate();


  function onClick(model) {
    console.log("Thumb onClick:", model);
    // Force refresh page and open the live page, because the live page of the StripChat has problems no fixed.
    // React will no refresh live page if use.
    // navigate("/model/" + model.platform + "/" + model.modelName, {
    //   state: {
    //     platform : model.platform,
    //     modelName: model.modelName
    //   }
    // })

    // const naviURL = document.location.origin + "/model/" + model.platform + "/" + model.modelName


    window.history.pushState({}, "", window.location.herf); // add back button.
    // const naviURL = document.location.origin + "/model?p=" + model.platform + "&m=" + model.modelName
    // const naviURL = document.location.origin + "/" + model.platform + "?p=" + model.platform + "&m=" + model.modelName
    const naviURL = `${document.location.origin}/${model.platform}?m=${model.modelName}`

    console.log("naviURL: ", naviURL);
    window.location.replace(naviURL);
  }

  React.useEffect(() => {
    console.log("Home mounted");
    // if (mountRef.current <= 0) {
    // mountRef.current++;
    if (props.home.control.isRefresh) {
      props.home.control.isRefresh = false;
      getModels(props);
    }
  })

  return (
    props.home.currentThumbs.map((model, index) => {
      return (
        <div className="col-xl-2 col-md-3 col-sm-4 mb-3" key={index} onClick={() => { onClick(model) }} >
          <div className="video-card">
            <div className="video-card-image">
              <a className="play-icon" ><i className="fas fa-play-circle"></i></a>
              <a ><img className="img-fluid" src={model.img} alt=""></img></a>
              <div className="time">{model.favorited}</div>
            </div>
            <div className="video-card-body">
              <div className="video-page text-success">
                <a title="" data-placement="top" data-toggle="tooltip" data-original-title="Verified" >{model.modelName}
                  <i className="fas fa-check-circle text-success"></i>
                </a>
              </div>
              <div className="video-view">
                {model.views} views &nbsp;
                {/* <i className="fas fa-calendar-alt"></i> 11 Months ago */}
              </div>
              <div className="video-title">
                <a > {subjectPretty(model.subject)} </a>
              </div>
            </div>
          </div>
        </div>
      )
    })
  )
}

function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    parseApiResponse(platform, response, searchText) {
      dispatch({ type: "HOME_PARSE_API_RESPONSE", platform: platform, response: response, searchText: searchText })
    },
    clickModel(name) {
      dispatch({ type: "THUMB_CLICK_MODEL", name: name })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedThumb);