import StripChatApi from "../../apis/StripChat/StripChatApi";

const TopbarState = {
  searchText: "",
  signupUrl: StripChatApi.config.signup.wlUrl(),
};

const TopbarReducer = (state = TopbarState, action) => {
  switch (action.type) {
    case "TOPBAR_SEARCH":
      state.searchText = action.text;
      console.log("TOPBAR_SEARCH:", state.searchText, action.text);
      return {
        ...state,
      };
      case "SIDEBAR_SWITCH_TAG":
        state.searchText = action.name;
        console.log("SIDEBAR_SWITCH_TAG:", state.searchText, action.name);
        return {
          ...state,
        };
    default:
      return state;
  }
};

export default TopbarReducer;
