function defaultBars() {
  let bars = [
    {
      category: "AGE",
      tags: [
        {
          name: "teens",
          title: "Teen 18+",
        },
        {
          name: "young",
          title: "Young 22+",
        },
        {
          name: "milfs",
          title: "MILF",
        },
        {
          name: "mature",
          title: "Mature",
        },
        {
          name: "grannies",
          title: "Granny",
        },
      ],
    },
    {
      category: "ETHNICITY",
      tags: [
        {
          name: "arab",
          title: "Arab",
        },
        {
          name: "asian",
          title: "Asian",
        },
        {
          name: "ebony",
          title: "Ebony",
        },
        {
          name: "indian",
          title: "Indian",
        },
        {
          name: "latin",
          title: "Latina",
        },
        {
          name: "white",
          title: "White",
        },
      ],
    },
    {
      category: "BODY TYPE",
      tags: [
        {
          name: "skinny",
          title: "Skinny",
        },
        {
          name: "athletic",
          title: "Athletic",
        },
        {
          name: "medium",
          title: "Medium",
        },
        {
          name: "curvy",
          title: "Curvy",
        },
        {
          name: "bbw",
          title: "BBW",
        },
      ],
    },
    ,
    {
      category: "HAIR",
      tags: [
        {
          name: "blondes",
          title: "Blonde",
        },
        {
          name: "brunettes",
          title: "Brunette",
        },
        {
          name: "redheads",
          title: "Redhead",
        },
        {
          name: "colorful",
          title: "Colorful",
        },
      ],
    },
  ];
  return bars;
}

const SidebarState = {
  activeBar: 0, // 0 is first of routers.
  bars: defaultBars(),
};

function parseBars(sheets) {
  let bar = {
    category: "Hot",
    tags: [
      // {
      //   name: "chinese",
      //   title: "Chinese",
      // },
    ],
  };

  for (let key in sheets) {
    if (key === "SIDEBAR_BARS") {
      // console.log("parse bars:", sheets[key]);
      const tags = sheets[key].split(",");
      for(const tag in tags)
      {
        // console.log("tag: ", tags[tag]);
        if(tags[tag] && tags[tag].length > 0)
        {
          bar.tags.push({
            name: tags[tag],
            title: tags[tag][0].toUpperCase() + tags[tag].substring(1),
          });

          // console.log("tags: ", bar.tags)
        }
      }
      break;
    }
  }

  return bar;
}

const SidebarReducer = (state = SidebarState, action) => {
  switch (action.type) {
    case "SIDEBAR_SWITCH":
      state.activeBar = action.index;
      return {
        ...state,
      };
    case "HOME_UPDATE_SIDEBAR_BARS":
      let bars = parseBars(action.sheets);
      if (bars) {
        state.bars = defaultBars();
        if(bars.tags.length > 0)
        {
          state.bars.splice(0, 0, bars);
        }
      }
      return {
        ...state,
      };
    case "TOPBAR_SEARCH":
      state.activeBar = 0; // search actions always show the results on the home page.
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default SidebarReducer;
