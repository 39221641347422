import React from 'react'
import { connect } from "react-redux";


function Gdpr(props) {
  return (
    <>
      <h2>GDPR</h2>
      <div className="single-video-info-content box mb-3">
        <h5><strong>ATTENTION: PLEASE READ THE FOLLOWING VERY CAREFULLY BEFORE USING THIS WEBSITE.</strong></h5>

        <p>This HERECAMS.com Data Protection Policy ("Policy") is a part of the HERECAMS.com ("HERECAMS") Privacy Policy. In the course of offering and providing services to the public, HERECAMS will gather certain information about individuals and will use this information in the normal course of business. Information may be gathered from website visitors, prospective customers, customers, suppliers, channel partners, resellers, affiliates, business contacts, employees and other people the organisation has a relationship with or may need to contact from time to time. This Policy describes how personal data will be collected, processed, handled and stored to meet HERECAMS's data protection standards and to comply with applicable law.</p>

        <h5><strong>PURPOSE OF THIS DATA PROTECTION POLICY</strong></h5>
        <p>This Policy ensures that HERECAMS:</p>

        <ul className='ul-disc' >
          <li>Complies with any applicable data protection laws and follows good business practices.</li>
          <li>Protects the rights of website visitors, prospective customers, customers, suppliers, channel partners, resellers, affiliates, employees, vendors and other partners.</li>
          <li>Is transparent about how it collects, stores and processes personal data.</li>
          <li>Protects data from the risk of a data breach.</li>
        </ul>
        
        <br></br>
        <h5><strong>WHAT IS THE GENERAL DATA PROTECTION REGULATION?</strong></h5>
        <p>The European Union has adopted the General Data Protection Regulation ("GDPR") which replaces the Data Protection Directive 95/46/EC ("Directive"). The GDPR was designed to harmonise data privacy laws across Europe, to protect and empower all EU citizens' data privacy and to reshape the way organisations who handle EU citizen's data approach data privacy. The GDPR aims primarily to give control back to citizens and residents over their personal data and to simplify the regulatory environment for international business by unifying the regulations within the EU.</p>

        <h5><strong>WHAT IS "PERSONAL DATA"?</strong></h5>
        <p>"Personal Data" is similarly defined in both the Directive and the GDPR as any information relating to a person who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that person. Examples of Personal Data identifiers include IP address and cookies and may well be regarded as personal data if these identifiers can be or are capable of being, without undue effort, linked back to the individual data subject.</p>

        <h5><strong>DATA COLLECTION WHEN YOU VISIT OUR WEBSITE</strong></h5>
        <p>You can visit our website (herecams.com) without herecams collecting your personal information. HERECAMS does use Google Analytics and other third party cookies in order to improve the user experience, analyse how our website is used and for advertising and marketing purposes. Details on the use of Google Analytics as well as other third party cookies is set out in the Privacy Policy.</p>

        <h5><strong>DATA COLLECTION WHEN YOU WHEN YOU CONTACT US</strong></h5>
        <p>You may certainly visit the HERECAMS website without disclosing your Personal Data, however, once you contact HERECAMS through our website or any other method, HERECAMS collects information about you that you submit. When you contact HERECAMS, you may provide personal information such as your name, phone number, email address, and organisation information that will be collected, processed and stored so that it is possible for us to contact and respond to your request, and/or provide various services to you and/or your organisation. When you contact us to request the provision of services, your Personal Data and other information shall be handled as set out in the section below.</p>

        <h5><strong>DATA COLLECTION WHEN YOU WHEN YOU REQUEST SERVICES</strong></h5>
        <p>You may register to become a member of HERECAMS and as a part of this process, you will disclose additional Personal Data and other information necessary for us to provide the proper services for your business and/or personal needs. HERECAMS will collect, process and store your Personal Data and other information using industry standard security practices to protect said information. All Personal Data and other information collected, processed and stored by HERECAMS for customers and potential customers will be stored until deletion is requested or said Personal Data and other information is deleted in the normal course of HERECAMS's business.</p>

        <h5><strong>SOCIAL MEDIA</strong></h5>
        <p>Our website features social media links and plugins designed for easy sharing and following our social media pages, which may be: Facebook, Twitter, Tumblr, LinkedIn, Instagram, You Tube, and Google Plus. Visiting the HERECAMS website does not automatically result in sharing data on these social media networks. These plug-ins remain inactive and idle until you click an icon. Once you click an icon, you will be taken to the applicable social media network. Each social media website and company has their own specific privacy policy and you should consult their policy before continuing to use the social media site.</p>

        <h5><strong>INFORMATION SECURITY</strong></h5>
        <p>HERECAMS strives to protect and ensure the security of Personal Data that is shared with us. When HERECAMS collects information about you, we also make sure that your information is protected from unauthorised access, loss, manipulation, falsification, destruction or unauthorised disclosure. This is done through appropriate industry standard technological measures.</p>

        <h5><strong>REQUEST FOR ACCESS TO INFORMATION</strong></h5>
        <p>You have the right to request access to the Personal Data that that we have collected about you. You can do this by contacting HERECAMS's Data Protection Officer at info@herecams.com . When you contact us requesting access to your information, we will provide you with a copy of the Personal Data we have about you. In order to comply with your request and to maintain the security of all Personal Data, we may ask you to verify your identity. We will fulfil your request by sending a copy to you electronically, unless the request expressly specifies a different method. For any subsequent access request, we may charge you with an administrative fee as set out in the GDPR or other applicable law.</p>

        <h5><strong>PERSONAL DATA CORRECTION AND DELETION</strong></h5>
        <p>If you believe that the Personal Data we have about you is incorrect, you have a legal right to provide us information for correction. We welcome and invite your contact so we can update your information and keep your Personal Data accurate. If at any point you wish for HERECAMS to delete Personal Data or information about you, you may contact the Data Protection Officer with a request to delete your Personal Data.</p>

        <h5><strong>WHEN THE POLICY APPLIES</strong></h5>
        <p>This Policy is applicable to visitors of HERECAMS website, as well as the services offered by HERECAMS directly via our website. HERECAMS is not responsible for the collection or processing of data by customers of HERECAMS. HERECAMS does not collect or process any data for or on behalf of any customers. Customers may collect and process data of visitors to the sites that they maintain on equipment licensed from HERECAMS, but the customers are completely and solely responsible for the data collected and processed. Our website may contain links to third party websites and once a link is clicked the visitor is redirected to a third party website where this Policy is no longer applicable. The "Effective Date" of this Policy is August 1, 2018.</p>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gdpr);