import React from 'react'
import { connect } from "react-redux";


function Privacy(props) {
  return (
    <>
      <h2>Privacy Policy</h2>
      <div className="single-video-info-content box mb-3">
        <h5><strong>ATTENTION:  PLEASE READ THE FOLLOWING VERY CAREFULLY BEFORE USING THIS WEBSITE.</strong></h5>

        <p>Herecams.com values the privacy of its partners and has created this Privacy Policy to let you know our dedication to protecting your privacy. You also have a right to know how your information is used and the steps we take to protect your personal information. This Privacy Policy is developed for the web site located at https://herecams.com/ and all related pages contained at that site. Any information you give us at our web site is held in strict confidence and will not be used in unauthorised ways.</p>

        <p>We are submitting data requests to third parties via their respective API (Application programming interface) to get information about webcam models particulars. We do not keep IP addresses or other user specific data, however, we do use such data in non-archiving ways, like: using your IP address to better customise content results based on your geo-location and show you models from your surrounding area, or to block you from viewing models who wish to be blocked in your area/country.</p>

        <h5><strong>USE OF INFORMATION</strong></h5>
        <p>Herecams.com requires you to provide contact information, including your email address, to complete the registration process. Herecams.com will utilise your email address to update you about our account and offers for you. Unless you opt-out as provided below, your email address will also be used to provide you technical support and general information on an as needed basis. Some broad non-personal summary information may be shared with third parties for demographic purposes, like geo customisation, but Herecams.com does not sell or share individual information with any third party.</p>

        <p>In addition, we use Google Analytics, a web analytics service provided by Google, Inc. (hereinafter referred to as "Google"), using third-party cookies that enable Google to analyse the use of the Website. The information generated by the cookie about the use of this website is transferred to a Google server in North America. Note that we have activated Google's IP anonymisation service "anonymiseIP" for our website which shortens a user's IP address prior to the transfer.</p>

        <p>Google will use this information on our behalf to evaluate the use of the Website, to compile reports for activities on the Website, and to provide other services to us relating to the Website.</p>

        <p>Google Analytics operates under the following terms:</p>

        <p>Terms of Service: http://www.google.com/analytics/terms/gb.html</p>

        <p>Privacy Statement: http://www.google.com/intl/en/analytics/learn/privacy.html</p>

        <p>Privacy Policy: http://www.google.com/intl/en/policies/privacy</p>

        <p>The user can prevent Google from collecting and processing the data concerning the use of the Website (including the IP address) by installing a browser-plug-in which is available under https://tools.google.com/dlpage/gaoptout</p>

        <h5><strong>SECURITY</strong></h5>
        <p>This site has security measures in place to protect the loss, misuse and alteration of the information under our control. We are very careful in handling your personal information. All login information you provide to us is transmitted for processing via SSL encryption technology, which is the current standard in Internet encryption.</p>

        <h5><strong>DATA PROTECTION POLICY</strong></h5>
        <p>In order to comply with the General Data Protection Regulation ("GDPR"), which was adopted by the European Union, Herecams.com has implemented a Data Protection Policy that sets out the way that we collect, process and store data of visitors and customers. The Data Protection Policy is located at https://herecams.com/gdpr. The Data Protection Policy is incorporated herein by reference and made a part of this Privacy Policy as if fully set out herein.</p>

        <h5><strong>LINKS AND PUBLIC FORUMS</strong></h5>
        <p>Herecams.com does not divulge private customer information to third parties or partners. However, this site contains links to other sites and Herecams.com is not responsible for the content of these sites or the privacy practices of these other sites. Also, Herecams.com provides or may provide at some future date chat rooms, message boards, and/or news groups for customers to use. Any information provided in these public forums is not protected by our privacy systems and this policy, so any information provided becomes public information. You should be careful what information you divulge in any public forum.</p>

        <h5><strong>COOKIES</strong></h5>
        <p>We use cookies to save your account information so that you do not have to re-enter it every time you visit our site. We may feature third party banner ads on our web site, which may contain cookies used by the advertiser, however we do not have access to this information. We also may use your IP address to help diagnose problems with our service and to administer our web site as well as to gather broad demographic information.</p>

        <h5><strong>OPT-OUT OF FUTURE MAILINGS</strong></h5>
        <p>In the normal course of business, we will provide you mailings about available products and services as well as technical support and security issues. While we strongly advise you to carefully read any mailing that we send due to the importance of your account, we do provide you the opportunity to opt-out from receiving these mailings. Please submit a work order requesting to opt-out from these mailings.</p>

        <h5><strong>CORRECT/UPDATE YOUR ACCOUNT INFORMATION</strong></h5>
        <p>We give customers the following options for changing and modifying information previously provided. You can make changes to your account at Herecams.com, you can submit a work order via https://herecams.com/ .</p>
        <p>Users/models who wish to be forgotten can send an email to info@herecams.com making such a request. A request for ones personal information will be honoured in a timely manner.</p>

        <h5><strong>CONTACTING US</strong></h5>
        <p>If you have any questions about this privacy statement, the practices of this site, or your dealings with this Web site, please use our Contact Us form or email us directly at info@herecams.com</p>
      </div>
    </>
  )
}


function mapStateToProps(state) {
  return {
    ...state
  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);