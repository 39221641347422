import axios from "axios";

const g_account = {
  auth_key: "AIzaSyAn2REYXXmIfEdKaHfFX442xEF53neg4RE",
};

const config = {
  baseUrl: "https://sheets.googleapis.com/v4/spreadsheets",
  path: function (sheetId, includeGridData, ranges) {
    let sheetUrl = "/" + sheetId + "/?key=" + g_account.auth_key;
    if (includeGridData) {
      sheetUrl = sheetUrl + "&includeGridData=true";
    }
    if (ranges) {
      sheetUrl = sheetUrl + "&ranges=" + ranges;
    }
    return sheetUrl;
  },
};

// Request
const option = {
  baseURL: config.baseUrl,
  header: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials:": "true",
  },
};

const request = axios.create(option);

request.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//

let g_sheets = {};

function parseSheets(response) {
  if (!response) {
    return {};
  }

  if (!response.sheets) {
    return {};
  }

  if (response.sheets.length < 1) {
    return {};
  }

  if (!response.sheets[0].data) {
    return {};
  }

  if (response.sheets[0].data.length < 1) {
    return {};
  }

  if (!response.sheets[0].data[0].rowData) {
    return {};
  }

  let sheets = {};
  for (let i = 0; i < response.sheets[0].data[0].rowData.length; i++) {
    const row = response.sheets[0].data[0].rowData[i].values;
    if (row && row.length >= 2) {
      // console.log("row:", row);
      if (row[0].formattedValue && row[1].formattedValue) {
        if (row[0].formattedValue && row[1].formattedValue) {
          sheets[row[0].formattedValue] = row[1].formattedValue;
        }
      }
    }
  }

  return sheets;
}

/*
RETURN VALUE
{
  ASIAN_H1_DESC: "BEST ASIAN CAM GIRLS"
  ASIAN_HEADER_DESC: "Want to join sexy Asian cam girls in free sex chat ? Browse our list of the best Asian teens or MILF connected right now. You'll find nude Asian women from China, Japan, Thailand and more. "
  ASIAN_KEYWORDS: "asian,chinese,china"
  DEFAULT_FOOTER_DESC: "FREE ADULT WEBCAMS WITH THE HOTTEST CAM GIRLS\nNo more need to open separately Stripchat, Flirt4Free, Bonga Cams or Live Jasmin to find the live sex cam that excites you.\nYou just have to browse the categories of HereCams to find your happiness among the physical characteristics that you like:\n\n- Age: all ages (+18 years old) whether you like teen cam girls, cougars or even mature.\n- Hair color: brunettes, blondes or cosplay with blue hair, as you prefer!\n- Body: from skinny teens to curvy naked women with big tits like Rapunzel.\n- Gender: thanks to HereCams there is nothing easier for you to find sex cams with hot girls, guys, trannies or couples.\n- Ethnicity: Asian teens like Mei_tin, naked Latina girls like Carolina Novoa, ebony camgirls and more\n- Attributes: hot milf with big tits, young teen with tight pussy...\n\nIf you like fetishism, you will also find your happiness with dominatrixes or submissive women ready to do anything to make you cum on cam.\n\nThe shows are 100% free in public, but you can leave tips to see a dance, blowjob, masturbation, penetration or any other fantasy that's on the menu. Some models have an interactive sex toy that allows you to participate and give them pleasure. They can also see you if they accept cam2cam."
  DEFAULT_H1_DESC: "Nude Live Sex Cams"
  DEFAULT_HEADER_DESC: "We gathered the best live sex cams from cams sites like Stripchat, Chaturbate and Live Jasmin. You'll find 24/7 a week thousands of naked women, guys, trans and couples available for live cams to chat with you. As soon as you'll start watching live porn cams with amateurs you won't go back to the boring and traditional porn videos! Even though girls are not always naked, at any time you might see them fingering or getting fucked. Hide your screen, this is definitely NSFW."
  SEPR_TITLE: "HereCams: Nude Live Sex Cams and Free Adult chat"
  WEBSITE_TITLE: "Nude Live Sex Cams and Free Adult chat"
}
*/
function parse(response) {
  g_sheets = parseSheets(response);
  console.log("parsed sheets: ", g_sheets);

  return g_sheets;
}

const GoogleSheetApi = {
  request: request,
  config: config,
  parse: parse,
  sheets: function () {
    return g_sheets;
  },
};

export default GoogleSheetApi;
